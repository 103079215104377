import React from 'react'
import axios from 'axios'

export default class TrainingDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
    }
  }

  componentDidMount() {
    const pageFullPath = this.props.location.pathname
    const url = pageFullPath.substring(
      pageFullPath.lastIndexOf('/') + 1
    )

    axios
      .get(`${process.env.API_URL}/umbraco/api/news/getDetail?url=${url}`)
      .then(result => {
        this.setState({
          data: result.data,
        })
      })
      .catch(error => { })
  }

  render() {
    const { data } = this.state;
    let name = '';
    let content = '';
    let columnItem = null;
    let author = '';
    let date = '';

    if (data) {
      author = data.Author;
      date = data.PublishedOn;
      content = data.Content;
      name = data.Name;

      if (data.Photo) {
        columnItem = (
          <div className="about-slide">
            <img alt="logo" src={`${process.env.API_URL}${data.Photo}`} />
          </div>
        );
      } else if (data.Video) {
        columnItem = (
          <div className="about-slide">
            <iframe src={data.Video} frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          </div>
        );
      }
    }

    const columnOneClasses = columnItem ? 'col-md-7 col-sm-12 col-xs-12' : 'col-sm-12 col-xs-12';

    return (
      <div>
        <section className="section-top">
          <div className="container">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="section-top-title">
                <h2>Notícias</h2>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12" />
          </div>
        </section>
        <section id="about" className="about_us section-padding">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-xs-12">
                <div className="about_content">
                  <div>
                    <h1>{name}</h1>
                   {/*  <div className="mt-s pull-right">
                      <div className="inline">
                        <h4 className="sub-header inline mr-sm">Data:</h4>
                        {date}
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className={columnOneClasses}>
                <div className="about_content">
                  <span dangerouslySetInnerHTML={{ __html: content }} />
                </div>
              </div>
              {
                columnItem ?
                  <div className="col-md-5 col-sm-12 col-xs-12">
                    {columnItem}
                  </div> : null
              }
            </div>
            <div className="row spacer-sm">
              <div className="col-md-2 col-sm-12 col-xs-12">

              </div>
              <div className="col-md-2 col-sm-12 col-xs-12">

              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
